export default {
	name: "doctor",
	data() {
		return {
			getPatient: {
				page: 1,
				size: 10,
				userType: Number,
				uuid: 'string',
				account: '',
				hospitalCode: '',
				name: '',
				hospitalName: ''
			},
			hospitalName: {
				name: '上海'
			},
			tableData: [],
			operateList: [],
			allHospital: [],
			total: 0,
			//关联患者
			doctorShow: Number,
			//禁用按钮
			statusShow: Number,
		}
	},
	created() {

	},
	mounted() {
		this.getConditionFromCache();
		this.getAllHospital();
		this.getPatientList();
		this.roleShow();
	},
	destroyed() {
		sessionStorage.removeItem("detail");
	},
	methods: {
		getConditionFromCache() {
			if (sessionStorage.getItem('detail')) {
				this.getPatient.page = Number(sessionStorage.getItem("currPage")) > 0 ? Number(sessionStorage.getItem(
					"currPage")) : 1;
				this.getPatient.size = Number(sessionStorage.getItem("size")) > 0 ? Number(sessionStorage.getItem(
					"size")) : 10;

				// account,deviceType,startCreateTime,endCreateTime
				let searchConditionStr = sessionStorage.getItem("serCondition");
				let searchCondition = JSON.parse(searchConditionStr);
				if(searchCondition!==null){
					this.getPatient.account = searchCondition.account;
					this.getPatient.hospitalName = searchCondition.hospitalName;
				}
				this.getPatientList();
			} else {
				this.getPatient.page = 1;
				this.getPatient.size = 10;

				sessionStorage.removeItem("currPage");
				sessionStorage.removeItem("size");
			}
		},
		//搜索
		getPatientListInquire() {
			this.getPatient.page = 1;

			let searchCondition = {
				account: this.getPatient.account,
				hospitalName: this.getPatient.hospitalName
			};
			sessionStorage.setItem("serCondition", JSON.stringify(searchCondition));

			this.getPatientList();
		},
		//医院接口
		getAllHospital() {
			console.log(this.hospitalName)
			let vm = this;
			this.$axios({
				method: "post",
				url: "/api/sys/web/hospital/page", // 接口地址
				data: vm.hospitalName
			}).then(res => {
				if (res.data.code === 200) {
					vm.allHospital = res.data.data;
					if (vm.allHospital != null) {
						vm.allHospital.forEach(function(item, index) {
							vm.$set(item, 'value', []);
							vm.allHospital[index].value = vm.allHospital[index].name;
						})
					}
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
		hospitalSelect(item) {
			console.log(item)
			if (item == '') {
				console.log(121)
			} else {
				this.hospitalName.name = item;
			}
			this.getAllHospital();
			console.log(this.hospitalName)
		},
		hospitalSearch(queryString, cb) {
			var allHospital = this.allHospital;
			var results = queryString ? allHospital.filter(this.createFilter(queryString)) : allHospital;
			// 调用 callback 返回建议列表的数据
			cb(results);
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		//审核带参跳转页面
		doIsVerifiedy(uuid) {
			this.$router.push({
				path: 'doctor-detail',
				query: {
					getDoctorManageUuid: uuid,
				}
			});
		},
		doDetail(uuid) {
			this.$router.push({
				path: 'doctor-detail',
				query: {
					getDoctorManageUuid: uuid,
					isEdit: '0'
				}
			});
		},
		//关联患者跳转页面
		doRelevancePatient(uuid) {
			this.$router.push({
				path: '/patient/doctor-patient',
				query: {
					getDoctorManageUuid: uuid,
				}
			});
		},
		goAccount(uuid) {
			console.log(uuid,'@@@@')
			this.$router.push({
				path: '/doctor-detail',
				query: {
					getDoctorManageUuid: uuid,
					isEdit: '0'
				}
			});
		},
		//根据角色显示隐藏列表功能
		//用户类型：1管理员；2医生；3患者
		roleShow() {
			const userType = sessionStorage.getItem('userType');
			if (userType == 1) {
				this.doctorShow = true;
				this.statusShow = true;
			} else {
				this.doctorShow = false;
				this.statusShow = false;
			}
		},
		//禁用用户
		doForbidden(uuid) {
			this.$confirm("您确定删除吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				this.$axios.get('/api/sys/web/user/del/' + uuid).then(res => {
					if (res.data.code === 200) {
						this.getPatientList();
					}
					this.$message.success({
						message: res.data.message,
					});
				}).catch(error => {
					console.log(error);
					//this.$message.error("请求失败");
				});
			}).catch(() => {});
		},
		//启用用户
		dostartUsing(uuid) {
			this.$confirm("您确定启用吗?", "提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				lockScroll: false,
				type: "warning"
			}).then(() => {
				this.$axios.get('/api/sys/web/user/status/' + uuid + "/" + 1).then(res => {
					if (res.data.code === 200) {
						this.getPatientList();
					}
					this.$message.success({
						message: res.data.message,
					});
				}).catch(error => {
					console.log(error);
					//this.$message.error("请求失败");
				});
			}).catch(() => {});
		},
		// 监听 pagesize 改变的事件
		handleSizeChange(newSize) {
			this.getPatient.size = newSize;
			this.getPatientList();

			sessionStorage.setItem('size', newSize);
			sessionStorage.setItem('currPage', this.getPatient.page);
		},
		//监听 页码值 改变的事件
		handleCurrentChange(newPage) {
			this.getPatient.page = newPage;
			this.getPatientList();

			sessionStorage.setItem('currPage', newPage);
			sessionStorage.setItem('size', this.getPatient.size);
		},
		/*获取患者列表数据*/
		getPatientList() {
			if (this.getPatient.hospitalName == '') {
				this.getPatient.hospitalCode = ''
			} else if (this.allHospital.length === 1 && this.allHospital[0].name === this.getPatient.hospitalName &&
				this.allHospital[0].hospitalUnifiedSocialCreditCode !== '') {
				this.getPatient.hospitalCode = this.allHospital[0].code;
			} else if (this.allHospital.length === 0) {
				this.getPatient.hospitalCode = "abc"
			} else {
				this.getPatient.hospitalCode = ''
			}
			console.log(this.getPatient)
			this.tableData.splice(0, this.tableData.length); //清空数组
			this.$axios({
				method: "post",
				url: "/api/sys/web/doctor/page", // 接口地址
				data: this.getPatient
			}).then(res => {
				if (res.data.code === 200) {
					this.tableData = res.data.data;
					this.totalPages = res.data.pageData.totalPages;
					this.total = res.data.pageData.totalElements;

					this.$refs.pagination.internalCurrentPage = this.getPatient.page;
				} else {
					this.$message({
						message: "操作失败",
						type: "error",
					});
				}
			}).catch(error => {
				console.log(error);
				//this.$message.error("请求失败");
			});
		},
	}
}
